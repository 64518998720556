/* eslint-disable import/no-unresolved */
import { MIDDLEWARE_URL } from '@/lib/constants';

export const getVideoPage = async (slug: string) => {
  if (!slug) return null;
  try {
    const resp = await fetch(`${MIDDLEWARE_URL}/middleware/v4/getVideoPageWeb?slug=${slug}`);
    if (!resp.ok) {
      throw new Error(`Error fetching video page data for ${slug}`);
    }
    const data = await resp.json();
    if (!data) {
      throw new Error(`Error parsing video page data for ${slug}`);
    }
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getVideo = async (slug: string) => {
  if (!slug) return null;
  try {
    const resp = await fetch(`${MIDDLEWARE_URL}/middleware/v2/getVideo?slug=${slug}`);
    if (!resp.ok) {
      throw new Error(`Error fetching video URL for ${slug}`);
    }
    const data = await resp.json();
    if (!data) {
      throw new Error(`Error parsing video URL for ${slug}`);
    }
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getVideoURL = async (url, token) => {
  if (!url) return null;
  if (!token) return null;
  try {
    const resp = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!resp.ok) {
      throw new Error(`Failed to fetch video URL`);
    }

    const data = await resp.json();
    return data.destination;
  } catch (err) {
    console.error(err);
    return null;
  }
};
