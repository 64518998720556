/* eslint-disable import/no-unresolved */
import { MIDDLEWARE_URL } from '@/lib/constants';

export const getJSON = async (urlStr: string, token: string) => {
  if (!urlStr) return null;
  try {
    const response = await fetch(MIDDLEWARE_URL + urlStr, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error fetching data from url: ${urlStr}. Error: ${errorData?.message ?? response.status}`);
    }

    const data = await response.json();
    if (!data) {
      throw new Error(`Error parsing data from url: ${urlStr}`);
    }
    return data;
  } catch (err) {
    console.error(err.message);
    return null;
  }
};
