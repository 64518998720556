/* eslint-disable import/no-unresolved */
import { getJSON } from './index';

export const getSubscriptionInfo = async (token: string) => {
  if (!token) return null;
  const url = '/middleware/v2/getSubscriptionInfo';
  try {
    const data = await getJSON(url, token);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
