/* eslint-disable import/no-unresolved */
import { MIDDLEWARE_URL } from '@/lib/constants';

export const getClipBySlug = async (slug: string) => {
  if (!slug) return null;
  try {
    const resp = await fetch(`${MIDDLEWARE_URL}/middleware/v4/getClip?slug=${slug}`);
    if (!resp.ok) {
      throw new Error(`Error fetching clips page data for ${slug}`);
    }
    const data = await resp.json();
    if (!data) {
      throw new Error(`Error parsing clips page data for ${slug}`);
    }
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getClipUrl = async (url: string, token: string) => {
  if (!url) return null;
  try {
    const resp = await fetch(MIDDLEWARE_URL + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!resp.ok) {
      throw new Error(`Error fetching clips page data for ${url}`);
    }
    const data = await resp.json();
    if (!data) {
      throw new Error(`Error parsing clips page data for ${url}`);
    }
    return data;
  } catch (err) {
    console.error(err.message);
    return null;
  }
};
